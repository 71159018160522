export const Divider = ( { text, btnText, btnCallback, extraClass="", _id=''} ) => {
  
    return (
        <div id={_id} className={"bg-primary uk-padding-small uk-margin-large-bottom " + extraClass}>
          <div className="uk-grid uk-flex-around uk-flex">
            <p className="font-opensans-extrabold-italic color-white fs-px-40 uk-flex item-center"><b className="uk-text-center">{ text }</b></p>
            <button onClick={ btnCallback } className="uk-button my-button-primary btn-pulse uk-margin-small-left font-roboto-condensed-light">
              <p id="textDividerBtn" className="uk-padding-small fs-px-35"> {btnText} </p>
            </button>
          </div>
        </div>
    );
}