import { Functions } from "./Helpers/MyWindow";


export const SomeNews = ({ pathNew, datetime, title, bodyText, tWidth=250 }) => {
    return (
        <article className="uk-card uk-grid uk-grid-collapse uk-width-expand@m uk-margin-bottom uk-margin-medium-bottom uk-margin-top">
            <div className="uk-card-media-left">
                <img src={pathNew} className="uk-cover" width={tWidth} />
                {/* <canvas width="100" height="400"></canvas> */}
            </div>

            <div className="uk-card-body uk-width-expand@m uk-margin-left">
                <h3 className="uk-card-title fs-px-20 uk-margin-remove"><b>{title}</b></h3>
                <p className="uk-text-meta uk-margin-remove">{datetime}</p>
                <p className="uk-text-italic">{bodyText} Si quieres más información sobre este nuevo sistema haz clic aquí <a href="http://geodac.com.ar/" target="_blank">GeoDAC</a></p>
            </div>

        </article>
    );
}