import UIkit from "uikit";
import { Assets } from "../assets/Assets";

const ITEMS_LIST = {
    'Empresa': '#section-empresa',
    'Soluciones': '#section-soluciones',
    'Novedades': '#section-novedades',
    'Contacto': '#section-contacto'
} // 'ITEM DEL MENU': '#hastaDondeHacerScroll'

export const Nav = () => {

    const handleClickOpen = () => {
        const element_main = document.getElementById('menuToShow');
        UIkit.offcanvas(element_main).show();
    }

    const closeSlideMenu = () => {
        const element_main = document.getElementById('menuToShow');
        UIkit.offcanvas(element_main).hide();
    }

    // { start } es el momento en el que se van a aplicar el resto de las propiedades. Es en height supongo
    const ukStickyProperties = "start: 800; animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky bg-primary color-white uk-margin-remove-top padding-45px; cls-inactive: uk-position-absolute";
    const ukStickyPropertiesMob = "start: 700; animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky bg-primary color-white; cls-inactive: uk-position-absolute";
    return (<>
        <div className="navmenu-slide">
           
            <div className="my-offcanvas" id="menuToShow">
                <div className="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide" id="body-canvas">
                    <img src={Assets.IMG.MAKER_DEFAULT} width={200} />
                    {/* <img src={Assets.IMG.M_DEFAULT} width="75" /> */}
                    <button
                        onClick={closeSlideMenu}
                        className="cur-pointer uk-offcanvas-close border-none my-button-primary uk-margin-small-right uk-margin-small-top btn-close-navmenu">
                        <svg width="10" height="10" viewBox="0 0 14 14">
                            <line fill="none" stroke="#fff" strokeWidth="3" x1="1" y1="1" x2="13" y2="13"></line>
                            <line fill="none" stroke="#fff" strokeWidth="3" x1="13" y1="1" x2="1" y2="13"></line>
                        </svg>
                    </button>
                    <ul className="uk-nav fs-px-20 uk-margin-large-top">
                        {
                            Object.keys(ITEMS_LIST).map( key => {
                                return (
                                    <li key={key}>
                                        <a className="btn-slide-menu uk-flex uk-flex-between" onClick={closeSlideMenu} href={ITEMS_LIST[key]}>
                                            { key }
                                            <svg width="20" height="20" viewBox="0 0 20 20" className="">
                                                <polyline fill="none" stroke="#fff" strokeWidth="3" points="7 4 13 10 7 16"></polyline>
                                            </svg>
                                        </a>
                                    </li>
                                );
                            })
                        }
                    </ul>

                </div>
            </div>
        </div>

        {/* MENU HEADER */}
        <div uk-sticky={ukStickyProperties}>    
            <nav id="navmenu-main" className="uk-padding uk-navbar uk-navbar-container uk-navbar-transparent uk-position-top uk-margin-small-top" >
                <div className="uk-position-center-left uk-margin-left">
                    <a href="https://maker.ar">
                        <picture>
                            <source srcSet={Assets.IMG.M_DEFAULT} media="(max-width: 500px)" width="70" />
                            <img src={Assets.IMG.MAKER_DEFAULT} width="175" />
                        </picture>
                    </a>
                </div>
                <div className="uk-position-center-right uk-margin-right fs-px-20 nav-menu">
                    {
                        Object.keys(ITEMS_LIST).map( key => {
                            return (
                                <a key={key} onClick={ () => {} }  className="uk-margin-right text-decoration-none btn-contact" href={ITEMS_LIST[key]}>
                                    { key }
                                </a>
                            );
                        })
                    }
                </div>
            </nav>
        </div>

        {/* Boton MENU DE SLIDE */}
        <div uk-sticky={ukStickyPropertiesMob}>
            <nav className="navmenu-slide uk-navbar uk-navbar-container uk-navbar-transparent uk-position-top ">
                <a onClick={handleClickOpen} 
                className="uk-button uk-button-default uk-toggle border-none uk-flex item-center uk-width-small uk-margin-small-top uk-margin-small-bottom">
                    <svg width="20" height="20" viewBox="0 0 20 20">
                        <rect x="2" y="5" width="20" height="1" stroke="#fff"></rect>
                        <rect x="2" y="11" width="20" height="1" stroke="#fff"></rect>
                        <rect x="2" y="17" width="20" height="1" stroke="#fff"></rect>
                    </svg>
                </a>
            </nav>
        </div>
    </>
    );
}