import { Assets } from '../assets/Assets';
import { Carousel } from './Carousel';
import { Clientes } from './Clientes';
import { Divider } from './Divider';
import { News, NewsSimple } from './News';

import '../assets/styles.css';
import { Functions } from './Helpers/MyWindow';

const MSG_WPP = 'Hola, ¿qué tal?';
const DATA_WPP = `https://api.whatsapp.com/send?phone=+5492235838831&text=${MSG_WPP}`;

export const Main = () => {

    const handleConoceMasGeoDac = () => {
        Functions.openWindow('http://geodac.com.ar/');
    }

    const callbackOpenWpp = () => {
        Functions.openWindow(DATA_WPP);
    }

    const handleConoceMasGeoCar = () => {
        callbackOpenWpp();
    }

    return (
        <main className="uk-margin-xlarge-top">

            <section className="uk-width-expand" id="section-empresa" style={{height: '100px'}}></section>
            <section className="uk-container">
                <h1 className="color-primary"><b>Somos Maker</b></h1>

                <div className="uk-child-width-1-2@m uk-grid color-black" style={{fontSize: '18px'}}>
                    <div>
                        <div className="uk-card">

                            <div className="uk-margin fs-px-21">
                                <p>
                                    Bienvenidos a MAKER, una empresa de tecnología
                                    dedicada a ofrecer soluciones innovadoras y
                                    personalizadas para satisfacer las necesidades de
                                    nuestros clientes. Desde 1997, nos hemos enfocado en
                                    desarrollar tecnología de vanguardia para brindar
                                    soluciones de calidad y de alto impacto a nuestros
                                    clientes.
                                </p>
                                <p>
                                    Nuestro equipo de expertos en tecnología está
                                    comprometido con la excelencia y la mejora continua.
                                    Nos mantenemos a la vanguardia de los avances
                                    tecnológicos para ofrecer soluciones actualizadas y a la
                                    medida de cada uno de nuestros clientes.
                                </p>
                            </div>
                            <div className="uk-card-media-bottom">
                                <img src={Assets.IMG.IMG_2_SOMOS_MAKER} alt="" className="uk-cover uk-margin-large-top" />
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <hr id="dividerSomosMaker"/>
                        <div className="uk-card">
                            <div className="uk-card-media-top">
                                <img src={Assets.IMG.IMG_1_SOMOS_MAKER} className="uk-cover" />
                            </div>
                            <div className="uk-margin fs-px-21 uk-margin-large-top">
                                <p>
                                    Nuestros productos y servicios están diseñados para
                                    mejorar la eficiencia y la rentabilidad de su empresa.
                                    Ofrecemos soluciones innovadoras para el control y la
                                    gestión de flotas, la automatización de procesos y la
                                    implementación de herramientas de análisis de datos.
                                    En MAKER, estamos comprometidos con el éxito de
                                    nuestros clientes. Nuestra filosofía se basa en trabajar de
                                    la mano con nuestros clientes, comprender sus
                                    necesidades y brindarles soluciones personalizadas.
                                    Además, ofrecemos un servicio de soporte técnico de
                                    alta calidad para garantizar el correcto funcionamiento
                                    de nuestros productos.
                                </p>
                                <p>
                                    Le invitamos a conocer más sobre nuestros productos y
                                    servicios, y a descubrir cómo podemos ayudarle a llevar
                                    su empresa al siguiente nivel con nuestras soluciones
                                    tecnológicas de vanguardia.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

            </section>

            <Divider
                text={"Tenemos una solución para tu negocio"}
                btnText={"CONTACTANOS"}
                btnCallback={callbackOpenWpp}
                extraClass="uk-margin-large-top"
            />

            <section className="uk-container" id="section-soluciones">
                <div className="uk-grid-small uk-child-width-expand@l uk-text-center uk-margin-large-top uk-grid">

                    <div className="uk-text-center uk-padding">
                        <a href="#preGeodac" id="appConductoresBtn" className="btn-pulse-panel uk-inline-clip uk-transition-toggle uk-light" tabIndex="0">
                            <img className="btn-services" src={Assets.ICON.CAR} width="100" height="100" alt="" />
                        </a>
                        <h2 className="uk-margin-small-top font-open-sans-bold fs-25"><strong>GeoDAC</strong></h2>
                        <div className="fs-px-21 color-black">
                            <div>Solución integral, práctica y económica utiliza tecnología</div>
                            <div>web y móvil para ofrecer servicios de traslado</div>
                            <div>automatizados y eficientes, que resultan en una mayor</div>
                            <div>rentabilidad para su negocio</div>
                        </div>
                    </div>

                    <div className="uk-text-center uk-padding">
                        <a href="#preGeocar" id="" className="btn-pulse-panel uk-inline-clip uk-transition-toggle uk-light" tabIndex="0">
                            <img className="btn-services" src={Assets.ICON.GEO_CAR} width="100" height="100" alt="" />
                        </a>
                        <h2 className="uk-margin-small-top font-open-sans-bold"><strong>GeoCAR</strong></h2>
                        <div className="fs-px-21 color-black">
                            <div>Seguimiento e información online sobre ubicación e</div>
                            <div>historial de recorridos de flota vehicular</div>
                        </div>
                    </div>

                </div>
            </section>
            
            <section className="uk-container">
                <div className="uk-margin-xlarge-top">
                    <div className="fs-px-40 color-primary uk-text-center uk-padding-small font-opensans-semibold-italic" >
                        <p className="margin-padding-0">
                            En MAKER, te ayudamos a optimizar tus procesos y
                        </p>
                        <p className="margin-padding-0">
                            acelerar el crecimiento de tu negocio.
                        </p>
                    </div>
                </div>
            </section>

            <Divider
                _id="preGeodac"
                text={"Conocé mas de nuestras soluciones"}
                btnText={"CONTACTANOS"}
                btnCallback={callbackOpenWpp}
                extraClass="uk-margin-large-top"
            />

            <section id="section-geodac" className="uk-container">
                
                <h1 className="margin-0 color-primary font-roboto-regular"><b>Geo</b>DAC</h1>
                <h3 className="margin-0 color-primary">Movilidad inteligente</h3>

                <div className="uk-card uk-grid uk-grid-collapse uk-child-width-1-2@s uk-margin-medium-top">
                    <div className="uk-card-media-left uk-cover-container">
                        <img src={Assets.IMG.GEODAC_IMG} className="uk-cover uk-margin-small-bottom" />
                    </div>
                    <div>
                        <div className="uk-card-body uk-padding-remove-top fs-px-20">
                            <p>
                                Solución integral que utiliza tecnología web y celular para
                                un despacho inteligente, automático y/o manual de
                                viajes, logrando una mayor eficiencia a la hora de
                                asignarlos a las unidades de su flota.
                            </p>

                            <p>
                                Su diseño permite una fácil implementación, reduciendo
                                tiempos y costos, tanto en la central como en los móviles.
                            </p>
                            <button onClick={ handleConoceMasGeoDac } className="uk-position-bottom-right uk-text-italic uk-text-bold my-button-primary conoce-mas fs-px-17 uk-margin-medium-right">
                                Conoce mas
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <Divider
                _id="preGeocar"
                text={"La tecnología nunca descansa, nosotros tampoco"}
                btnText={"CONTACTANOS"}
                btnCallback={callbackOpenWpp}
                extraClass="uk-margin-large-top"
            />

            <section id="section-geocar" className="uk-container">

                <h1 className="margin-0 color-primary font-roboto-regular"><b>Geo</b>CAR</h1>
                <h3 className="margin-0 color-primary">Geolocalización de unidades móviles</h3>

                <div className="uk-card uk-grid uk-grid-collapse uk-child-width-1-2@s uk-margin-medium-top uk-margin-medium-bottom">
                    <div className="uk-card-media-left uk-cover-container">
                        <img src={Assets.IMG.GEOCAR_IMG} alt="" className="uk-cover uk-margin-small-bottom" />
                    </div>
                    <div>
                        <div className="uk-card-body uk-padding-remove-top fs-px-20">
                            <p>
                                GeoCAR es una herramienta de seguimiento
                                satelital que te permite gestionar y monitorear tus
                                flotas de vehículos de manera eficiente y segura.
                            </p>

                            <p>
                                <a href="https://play.google.com/store/apps/details?id=com.maker.geocarcontrolflota&hl=es_419">
                                    Descargue la App aquí
                                </a>
                            </p>
                            <div className="fs-px-32 color-primary uk-margin-large-top font-opensans-semibold-italic" >
                                <p className="uk-text-left">
                                    ¡Tené el control de tus vehículos las 24hs. del día!
                                </p>
                            </div>
                            <button onClick={ handleConoceMasGeoCar } className="uk-position-bottom-right uk-text-italic uk-text-bold my-button-primary conoce-mas fs-px-17 uk-margin-medium-right">
                                Conoce mas
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="uk-width-expand" id="section-novedades" style={{height: '100px'}}></section>
            <section className="uk-background-muted" id="">
                <div className="uk-container">
                    <h2 className="uk-margin-large-top uk-margin-small-bottom"><b>Novedades</b></h2>
                    <News />
                    <NewsSimple />
                </div>
            </section>

            <section>
                <Carousel />
            </section>

            <section className="uk-background-muted">
                <Clientes />
            </section>

            <Divider
                text={"Conocé más de nuestras soluciones"}
                btnText={"CONTACTANOS"}
                btnCallback={callbackOpenWpp}
            />
        </main>
    );
}