import { Assets } from '../assets/Assets';

export const Carousel = () => {
    // Al obtener las KEYS, obtenemos todas las agencias
    const key = Object.keys(Assets.IMG.CAROUSEL);

    return (
        <div className="uk-margin-large-bottom uk-margin-large-top">
            <div className="uk-text-center uk-margin-large-bottom">
                <h2 className="uk-text-bolder">Algunos de nuestros Clientes</h2>
            </div>

            <div className="slider" tabIndex="-1">
                <ul className="slide-track">
                    { // Los iconos se ponen dos veces para generar ese efecto de infinidad
                        key.map((value, index) => (
                            <li key={index} className="uk-width-auto slide">
                                <img src={Assets.IMG.CAROUSEL[value]} width="150" alt="" />
                                <div className="uk-position-center uk-panel"></div>
                            </li>
                            
                        ))
                    }
                    {
                        key.map((value, index) => (
                            <li key={index} className="uk-width-auto slide">
                                <img src={Assets.IMG.CAROUSEL[value]} width="150" alt="" />
                                <div className="uk-position-center uk-panel"></div>
                            </li>
                            
                        ))
                    }
                </ul>

            </div>
        </div>
    )
}