import { Assets } from "../assets/Assets";
import { SomeNews } from "./SomeNews"

const { LOGOS } = Assets.ICON;

export const News = () => {
    return (
        <section id="newsComponent">
            <div className="uk-position-relative uk-visible-toggle uk-margin-medium-top" tabIndex="-1" uk-slideshow="ratio: 3:2" >

                <ul className="uk-slideshow-items ">
                    <li className="">
                        <SomeNews
                            pathNew={LOGOS.N_BEL_REMISES}
                            title="Bel Remises: Lanzamiento Sistema GeoDAC en Marcos Paz, Prov. Buenos Aires"
                            datetime="03/08/2023"
                            bodyText="En Agosto 2023 comenzó a funcionar el Sistema GeoDAC en la agencia Bel Remises, en la ciudad de Marcos Paz, Provincia de Buenos Aires. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia Remis Urban por confiar en Maker para optimizar su flota." />

                        {/* <hr className="hr-list-divider" /> */}

                        <SomeNews
                            pathNew={LOGOS.N_URBAN}
                            title="Remises Urban: Lanzamiento Sistema GeoDAC en Tandil"
                            datetime="19/07/2023"
                            bodyText="En Julio 2023 comenzó a funcionar el Sistema GeoDAC en la agencia Remis Urban, en la ciudad de Tandil, Provincia de Buenos Aires. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia Remis Urban por confiar en Maker para optimizar su flota." />
                    </li>
                    <li className="">
                        <SomeNews
                            pathNew={LOGOS.N_LA_32}
                            title="La 32 Remises: Lanzamiento Sistema GeoDAC en Santa Teresita"
                            datetime="27/04/2023"
                            bodyText="En Abril 2023 comenzó a funcionar el Sistema GeoDAC en la agencia La 32 Remises, en la localidad de santa Teresita, Partido de La Costa, Provincia de Buenos Aires. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia La 32 Remises por confiar en Maker para optimizar su flota." />

                        {/* <hr className="hr-list-divider" /> */}

                        <SomeNews
                            pathNew={LOGOS.N_COLT}
                            title="Remis Colt: Lanzamiento Sistema GeoDAC en Villa Elvira"
                            datetime="20/03/2023"
                            bodyText="En Marzo 2023 comenzó a funcionar el Sistema GeoDAC en la agencia Remis Colt, en la localidad de Villa Elvira, Partido de La Plata, Provincia de Buenos Aires. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia Remis Colt por confiar en Maker para optimizar su flota." />
                    </li>
                    <li className="">
                        <SomeNews
                            pathNew={LOGOS.N_REMISES_NUEVO_SOL}
                            title="Remis Nuevo Sol: Lanzamiento Sistema GeoDAC en Venado Tuerto"
                            datetime="14/03/2023"
                            bodyText="En Marzo 2023 comenzó a funcionar el Sistema GeoDAC en la agencia Remis Nuevo Sol, en la ciudad de Venado Tuerto, Provincia de Santa Fe. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia Remis Nuevo Sol por confiar en Maker para optimizar su flota. " />

                        {/* <hr className="hr-list-divider" /> */}

                        <SomeNews
                            pathNew={LOGOS.N_REMISES_JR}
                            title="Remises JR: Lanzamiento Sistema GeoDAC en Venado Tuerto, Santa Fe"
                            datetime="24/02/2023"
                            bodyText="En Febrero 2023 comenzó a funcionar el Sistema GeoDAC en la agencia Remises JR, en la ciudad de Venado Tuerto, Provincia de Santa Fe. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia Remises JR por confiar en Maker para optimizar su flota." />
                    </li>
                </ul>

                {/* <a className="uk-position-center-left chevron-novedades" href="" uk-slideshow-item="previous"><img src={Assets.ICON.CHEVRON_LEFT} /></a>
                    <a className="uk-position-center-right chevron-novedades" href="" uk-slideshow-item="next"><img src={Assets.ICON.CHEVRON_RIGHT} /></a>
                 */}
                <div className="uk-position-bottom-center" id="dotnav-novedades" style={{top: '670px'}}>
                    <ul className="uk-dotnav" id="uk-dotnav-slide-novedades">
                        <li uk-slideshow-item="0" ><a className="uk--dotnav-circle" href="#"></a></li>
                        <li uk-slideshow-item="1" ><a className="uk--dotnav-circle" href="#"></a></li>
                        <li uk-slideshow-item="2" ><a className="uk--dotnav-circle" href="#"></a></li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

/**
 * 
 * @description Componente para los moviles
 */
export const NewsSimple = () => {
    return (
        <section id="newsSimpleComponent">
            <div className="uk-position-relative uk-visible-toggle" tabIndex="-1" uk-slideshow="ratio: false; min-height: 820">

                <ul className="uk-slideshow-items">
                    <li className="uk-padding-large uk-margin-top">
                        <SomeNews
                            pathNew={LOGOS.N_BEL_REMISES}
                            title="Bel Remises: Lanzamiento Sistema GeoDAC en Marcos Paz, Prov. Buenos Aires"
                            datetime="03/08/2023"
                            bodyText="En Agosto 2023 comenzó a funcionar el Sistema GeoDAC en la agencia Bel Remises, en la ciudad de Marcos Paz, Provincia de Buenos Aires. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia Remis Urban por confiar en Maker para optimizar su flota." />
                    </li>
                    <li className="uk-padding-large uk-margin-top">
                        <SomeNews
                            pathNew={LOGOS.N_URBAN}
                            title="Remises Urban: Lanzamiento Sistema GeoDAC en Tandil"
                            datetime="19/07/2023"
                            bodyText="En Julio 2023 comenzó a funcionar el Sistema GeoDAC en la agencia Remis Urban, en la ciudad de Tandil, Provincia de Buenos Aires. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia Remis Urban por confiar en Maker para optimizar su flota." />
                    </li>
                    <li className="uk-padding-large uk-margin-top">
                        <SomeNews
                            pathNew={LOGOS.N_LA_32}
                            title="La 32 Remises: Lanzamiento Sistema GeoDAC en Santa Teresita"
                            datetime="27/04/2023"
                            bodyText="En Abril 2023 comenzó a funcionar el Sistema GeoDAC en la agencia La 32 Remises, en la localidad de santa Teresita, Partido de La Costa, Provincia de Buenos Aires. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia La 32 Remises por confiar en Maker para optimizar su flota." />
                    </li>
                    <li className="uk-padding-large uk-margin-top">
                        <SomeNews
                            pathNew={LOGOS.N_COLT}
                            title="Remis Colt: Lanzamiento Sistema GeoDAC en Villa Elvira"
                            datetime="20/03/2023"
                            bodyText="En Marzo 2023 comenzó a funcionar el Sistema GeoDAC en la agencia Remis Colt, en la localidad de Villa Elvira, Partido de La Plata, Provincia de Buenos Aires. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia Remis Colt por confiar en Maker para optimizar su flota." />
                    </li>
                    <li className="uk-padding-large uk-margin-top">
                        <SomeNews
                            pathNew={LOGOS.N_REMISES_NUEVO_SOL}
                            title="Remis Nuevo Sol: Lanzamiento Sistema GeoDAC en Venado Tuerto"
                            datetime="14/03/2023"
                            bodyText="En Marzo 2023 comenzó a funcionar el Sistema GeoDAC en la agencia Remis Nuevo Sol, en la ciudad de Venado Tuerto, Provincia de Santa Fe. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia Remis Nuevo Sol por confiar en Maker para optimizar su flota. " />
                    </li>
                    <li className="uk-padding-large uk-margin-top">
                        <SomeNews
                            pathNew={LOGOS.N_REMISES_JR}
                            title="Remises JR: Lanzamiento Sistema GeoDAC en Venado Tuerto, Santa Fe"
                            datetime="24/02/2023"
                            bodyText="En Febrero 2023 comenzó a funcionar el Sistema GeoDAC en la agencia Remises JR, en la ciudad de Venado Tuerto, Provincia de Santa Fe. GeoDAC es una solución integral que utiliza tecnología web y celular. Agradecemos a la Agencia Remises JR por confiar en Maker para optimizar su flota." />
                    </li>
                </ul>
                <div className="">
                    <a className="uk-position-center-left chevron-novedades" href="" uk-slideshow-item="previous"><img src={Assets.ICON.CHEVRON_LEFT} /></a>
                    <a className="uk-position-center-right chevron-novedades" href="" uk-slideshow-item="next"><img src={Assets.ICON.CHEVRON_RIGHT} /></a>
                </div>
            </div>
        </section>
    );
}