import AUTOJET       from  './img/agencias/Autojet.png';
import VILLA_PARQUE  from  './img/agencias/VILLA_PARQUE.png';
import AL_TOKE       from  './img/agencias/al_toke.png';
import CATHEDRAL     from  './img/agencias/cathedral.png';
import CENTRO        from  './img/agencias/centro.png';
import CHOLOS        from  './img/agencias/cholos.png';
import CIELO         from  './img/agencias/cielo.png';
import DISQUEYA      from  './img/agencias/disque ya.png';
import ESTILO        from  './img/agencias/estilo.png';
import ESTRELLA      from  './img/agencias/estrella.png';
import GOLDEN        from  './img/agencias/golden.png';
import HORIZONTE     from  './img/agencias/horizonte.png';
import LN            from  './img/agencias/ln.png';
import MUNDO_REMIS   from  './img/agencias/mundo_remis.png';
import N_ARTIGAS     from  './img/agencias/nueva artigas.png';
import N_ESTRELLA    from  './img/agencias/nueva estrella.png';
import PASOCARS      from  './img/agencias/paso_cars.png';
import PREMIER       from  './img/agencias/premier.png';
import PREMIUN       from  './img/agencias/premiun.png';
import REMICOOP      from  './img/agencias/remicoop.png';
import R_CENTRO      from  './img/agencias/remis_centro.png';
import R_OESTE       from  './img/agencias/remises_oeste.png';
import SENIORAL      from  './img/agencias/señorial.png';
import SFC           from  './img/agencias/sfc.png';
import SXX1          from  './img/agencias/siglo_XXI.png';
import SU_TAXI       from  './img/agencias/su_taxi.png';
import S_COOP        from  './img/agencias/super_coop.png';
import UNIVERSITARIO from  './img/agencias/universitario.png';
import YRIGOYEN      from  './img/agencias/yrigoyen.png';
import MAKER_DEFAULT from './img/logo_maker_blanco.png';
import MAKER_BLUE    from './img/logo_maker_azul.png';
import M_DEFAULT     from './img/m-nodos.png';

// Iconos
import QUOTATION           from './icons/comillas.png';
import CAR                 from './icons/icono_app_conductores.png';
import GEO_CAR             from './icons/logo geocar.png'
import CHEVRON_RIGHT       from './icons/viñeta-next.png';
import CHEVRON_LEFT       from './icons/viñeta-prev.png';
import CHEVRON_LEFT_WHITE  from './icons/vineta-w-l.png';
import CHEVRON_RIGHT_WHITE from './icons/vineta-w-r.png';

import FACEBOOK  from './img/footer/icono FB.png';
import INSTAGRAM from './img/footer/icono IG.png';
import YOUTUBE   from './img/footer/icono youtube.png';

import PIN   from './img/footer/icono direccion.png';
import WPP   from './img/footer/icono Wapp.png';
import EMAIL from './img/footer/icono mail.png';
import PHONE from './img/footer/icono TE.png'; 

import IMG_1_SOMOS_MAKER from './img/somosmaker/imagen 01.png';
import IMG_2_SOMOS_MAKER from './img/somosmaker/imagen02.png';

import GEOCAR_IMG from './img/productos/GEOCAR imagen.jpg';
import GEODAC_IMG from './img/productos/GEODAC imagen.jpg';

import IMG_ROT_1 from './img/slide/rot-1.jpg';
import IMG_ROT_2 from './img/slide/rot-2.jpg';
import IMG_ROT_3 from './img/slide/rot-3.jpg';

import N_LA_32             from './img/AGENCIAS_NOVEDADES/La 32 Remises.jpg';              
import N_REMISES_JR        from './img/AGENCIAS_NOVEDADES/Remises JR.jpg';
import N_REMISES_NUEVO_SOL from './img/AGENCIAS_NOVEDADES/Remises Nuevo Sol.jpg';
import N_URBAN             from './img/AGENCIAS_NOVEDADES/Urban.jpg';
import N_BEL_REMISES       from './img/AGENCIAS_NOVEDADES/Bel Remises.jpeg';
import N_COLT              from './img/AGENCIAS_NOVEDADES/Colt.jpg';


export const Assets = {
    IMG: { // 1ro hay que importar la imagen, y despues agregarla en este objeto
        CAROUSEL: {
            AUTOJET: AUTOJET,
            VILLA_PARQUE: VILLA_PARQUE,
            AL_TOKE: AL_TOKE,
            CATHEDRAL: CATHEDRAL,
            CENTRO: CENTRO,
            CHOLOS: CHOLOS,
            CIELO: CIELO,
            DISQUEYA: DISQUEYA,
            ESTILO: ESTILO,
            ESTRELLA: ESTRELLA,
            GOLDEN: GOLDEN,
            HORIZONTE: HORIZONTE,
            LN: LN,
            MUNDO_REMIS: MUNDO_REMIS,
            N_ARTIGAS: N_ARTIGAS,
            N_ESTRELLA: N_ESTRELLA,
            PASOCARS: PASOCARS,
            PREMIER: PREMIER,
            PREMIUN: PREMIUN,
            REMICOOP: REMICOOP,
            R_CENTRO: R_CENTRO,
            R_OESTE: R_OESTE,
            SENIORAL: SENIORAL,
            SFC: SFC,
            SXX1: SXX1,
            SU_TAXI: SU_TAXI,
            S_COOP: S_COOP,
            UNIVERSITARIO: UNIVERSITARIO,
            YRIGOYEN: YRIGOYEN,
        },
        MAKER_DEFAULT: MAKER_DEFAULT,
        MAKER_BLUE: MAKER_BLUE,
        M_DEFAULT: M_DEFAULT,
        IMG_1_SOMOS_MAKER: IMG_1_SOMOS_MAKER,
        IMG_2_SOMOS_MAKER: IMG_2_SOMOS_MAKER,
        GEOCAR_IMG: GEOCAR_IMG,
        GEODAC_IMG: GEODAC_IMG,
        IMG_ROT_1: IMG_ROT_1,
        IMG_ROT_2: IMG_ROT_2,
        IMG_ROT_3: IMG_ROT_3,
    },
    ICON: {
        CHEVRON_LEFT: CHEVRON_LEFT,
        CHEVRON_RIGHT: CHEVRON_RIGHT,
        CHEVRON_LEFT_WHITE: CHEVRON_LEFT_WHITE,
        CHEVRON_RIGHT_WHITE: CHEVRON_RIGHT_WHITE,
        CAR: CAR,
        GEO_CAR: GEO_CAR,
        QUOTATION: QUOTATION,
        FACEBOOK: FACEBOOK,
        INSTAGRAM: INSTAGRAM,
        YOUTUBE: YOUTUBE,
        PIN: PIN,
        WPP: WPP,
        EMAIL: EMAIL,
        PHONE: PHONE,
        LOGOS: {
            N_LA_32: N_LA_32,
            N_REMISES_JR: N_REMISES_JR,
            N_REMISES_NUEVO_SOL: N_REMISES_NUEVO_SOL,
            N_URBAN: N_URBAN,
            N_BEL_REMISES: N_BEL_REMISES,
            N_COLT: N_COLT
        }
    }
}