import { Assets } from "../assets/Assets";

export const Header = () => {

    const ukSlideShow = "ratio: 7:3; animation: push; autoplay: true; autoplayInterval: 10000; duration: 2000;";
    const ukSlideParallax = "x: 100,0,0; opacity: 1,1,0";

    return (<>
        <header>
            <div
                className="uk-visible-toggle uk-light"
                uk-slideshow={ukSlideShow}
                uk-slider-parallax={ukSlideParallax}
                tabIndex="-1"
            >
                <div className="uk-position-relative">
                    <div>
                        <ul className="uk-slideshow-items" uk-height-viewport="offset-top: true; offset-bottom: 10">
                            <li>
                                <div className="uk-position-cover">
                                    <img src={Assets.IMG.IMG_ROT_1} className="img-contain" />
                                </div>
                                <div className="uk-margin uk-position-center uk-position-medium uk-text-center uk-light">
                                    <h1 className="fs-vw-4 uk-text-bold" uk-slideshow-parallax="x: 100,-100">Apasionados por la tecnología</h1>
                                    <p className="fs-vw-1-5 color-white uk-padding-large uk-padding-remove-top uk-padding-remove-bottom uk-margin-medium-top" uk-slideshow-parallax="x: 200,-200" id="title1">
                                        En 1997, fundamos nuestra empresa con la firme convicción de que la
                                        tecnología es el camino hacia el futuro. Nos definimos como un equipo
                                        multidisciplinario de jóvenes apasionados por la electrónica e informática,
                                        dedicados a desarrollar soluciones integrales de sistemas y equipamiento para
                                        el control satelital de flotas, empleando tecnología de última generación.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="uk-position-cover">
                                    <img src={Assets.IMG.IMG_ROT_2} alt="" className="img-contain" />
                                </div>
                                <div className="uk-position-center uk-position-medium uk-text-center uk-light">
                                    <h1 className="fs-vw-4 uk-text-bold" uk-slideshow-parallax="x: 100,-100">Juntos hacia el futuro: <br /> Creciendo con nuestros clientes</h1>
                                    <p className="fs-vw-1-5 color-white uk-padding-large uk-padding-remove-top uk-padding-remove-bottom uk-margin-medium-top" uk-slideshow-parallax="x: 200,-200">
                                        Trabajamos en colaboración con nuestros clientes, comprendiendo sus necesidades y ofreciéndoles soluciones para impulsar su crecimiento. Nuestro objetivo no es solo ser líderes en el mercado, sino también ser una empresa comprometida con los clientes.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="uk-position-cover">
                                    <img src={Assets.IMG.IMG_ROT_3} alt="" className="img-contain" />
                                </div>
                                <div className="uk-position-center uk-position-medium uk-text-center uk-light">
                                    <h1 className="fs-vw-4 uk-text-bold" uk-slideshow-parallax="x: 100,-100">Desde Mar del Plata, hacia el mundo </h1>
                                    <p className="fs-vw-1-5 color-white uk-padding-large uk-padding-remove-top uk-padding-remove-bottom uk-margin-medium-top" uk-slideshow-parallax="x: 200,-200">
                                        Contamos con un equipo de trabajo con amplia experiencia, que te acompañará en todo el proceso, desde la implementación hasta el soporte postventa. Con nuestras soluciones, podrás mejorar la calidad de servicio que ofreces a tus clientes y destacarte en un mercado cada vez más competitivo.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="uk-position-large uk-position-bottom uk-text-center uk-margin-large-bottom">
                        <h1 className="font-opensans-extrabold fs-vw-2">Somos Maker</h1>
                        <a href="#section-empresa" className="btn-conocenos uk-text-decoration-none">Conocenos</a>
                    </div>

                    <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="" uk-slideshow-item="previous"><img src={Assets.ICON.CHEVRON_LEFT_WHITE} />&nbsp;</a>
                    <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="" uk-slideshow-item="next"><img src={Assets.ICON.CHEVRON_RIGHT_WHITE} /></a>

                    <div className="uk-position-bottom-center uk-position-small">
                        <ul className="uk-dotnav uk-light" id="uk-dotnav-slide">
                            <li uk-slideshow-item="0" ><a className="uk--dotnav-circle" href="#"></a></li>
                            <li uk-slideshow-item="1" ><a className="uk--dotnav-circle" href="#"></a></li>
                            <li uk-slideshow-item="2" ><a className="uk--dotnav-circle" href="#"></a></li>
                        </ul>
                    </div>
                </div>

                {/* <div className="uk-position-top uk-height-small">
                    <Nav />
                </div> */}
            </div>
        </header>
    </>);
}