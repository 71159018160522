import { Assets } from "../assets/Assets";
import { Functions } from './Helpers/MyWindow';

export const Footer = () => {

    const {
        MAKER_BLUE
    } = Assets.IMG;

    const {
        WPP,
        PIN,
        EMAIL,
        PHONE,
        FACEBOOK,
        INSTAGRAM,
        YOUTUBE
    } = Assets.ICON;

    const MSG_WPP = 'Hola, ¿qué tal?';
    const MSG_MAIL = '';
    const DATA = {
        G_MAPS: 'https://goo.gl/maps/1rZ8fFXbMd551o516',
        WPP: `https://api.whatsapp.com/send?phone=+5492235838831&text=${MSG_WPP}`,
        MAIL_TO: `mailto:info@geodac.com.ar?subject=Implementar%20GeoDAC&body=${MSG_MAIL}`,
        PHONE: 'tel:+542236285111',
        YT: 'https://www.youtube.com/@Makerit',
        FB: 'https://www.facebook.com/maker.ar',
        IG: 'https://www.instagram.com/maker.ar/'
    }

    return (
        <footer id="section-contacto">
            <div className="">
                <section className="uk-flex-center uk-margin-large-bottom uk-grid"> {/* Este margin bottom esta para evitar que toque con el borde del <footer></footer> */}
                    <div className="uk-flex-center uk-margin-small-bottom uk-flex-first first-column" >
                        <h2 className="color-primary uk-margin-medium-left"><b>Contacto</b></h2>
                        <ul className="uk-list fs-px-19" id="contactosFooter">
                            <li className="uk-flex uk-flex-middle uk-margin-medium-top">
                                <span className="display-inline-block uk-margin-medium-left">
                                    <a onClick={ () => { Functions.openWindow(DATA.G_MAPS) } } className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer"><img className="bg-main bg-round-100" src={PIN} width={50} /></a>
                                </span>
                                <span className="uk-width-medium uk-margin-left dis-contact">
                                    <a onClick={ () => { Functions.openWindow(DATA.G_MAPS) } } className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer">Chile 1816 | Mdp | Argentina</a>
                                </span>
                                <span className="display-inline-block">
                                    <a  onClick={ () => { Functions.openWindow(DATA.WPP) } }className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer"><img className="bg-main bg-round-100" src={WPP} width={50} /></a>
                                </span>
                                <span className="uk-width-medium uk-margin-left dis-contact">
                                    <a onClick={ () => { Functions.openWindow(DATA.WPP) } } className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer">+54 9 223 583 8831</a> 
                                </span>
                            </li>
                            <li className="uk-flex uk-flex-middle uk-margin-medium-top">
                                <span className="display-inline-block uk-margin-medium-left">
                                    <a onClick={ () => { Functions.openWindow(DATA.MAIL_TO, 'Mailer') } } className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer"><img className="bg-main bg-round-100" src={EMAIL} width={50} /></a>
                                </span>
                                <span className="uk-width-medium uk-margin-left dis-contact">
                                    <a onClick={ () => { Functions.openWindow(DATA.MAIL_TO, 'Mailer') } } className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer">administracion@geodac.com.ar</a>
                                </span>
                                <span className="display-inline-block">
                                    <a href={DATA.PHONE} className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer"><img className="bg-main bg-round-100" src={PHONE} width={50} /></a>
                                </span>
                                <span className="uk-width-medium uk-margin-left dis-contact">
                                    <a href={DATA.PHONE} className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer">+54 223 6285111</a>
                                </span>
                            </li>
                        </ul>
                        <section id="contactosFooter760"> {/* este solo se muestra cuando esta menos de 760px de ancho */}
                            <div className="uk-margin-small">
                                <span className="display-inline-block">
                                    <a onClick={ () => { Functions.openWindow(DATA.G_MAPS) } } className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer"><img className="bg-main bg-round-100" src={PIN} width={50} /></a>
                                </span>
                                <span className="uk-width-medium uk-margin-left dis-contact">
                                    <a onClick={ () => { Functions.openWindow(DATA.G_MAPS) } } className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer">Chile 1816 | Mdp | Argentina</a>
                                </span>
                            </div>
                            <div className="uk-margin-small">
                                <a onClick={ () => { Functions.openWindow(DATA.WPP) } } className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer">
                                    <span className="display-inline-block"> <img className="bg-main bg-round-100" src={WPP} width={50} /> </span>
                                    <span className="uk-width-medium uk-margin-left dis-contact"> +54 9 223 583 8831 </span>
                                </a>
                            </div>
                            <div className="uk-margin-small">
                                <a onClick={ () => { Functions.openWindow(DATA.MAIL_TO, 'Mailer') } } className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer">
                                    <span className="display-inline-block"><img className="bg-main bg-round-100" src={EMAIL} width={50} /></span>
                                    <span className="uk-width-medium uk-margin-left dis-contact">administracion@geodac.com.ar</span>
                                </a>
                            </div>
                            <div className="uk-margin-small">
                                <a href={DATA.PHONE} className="uk-text-decoration-none color-black" target="_blank" rel="noopener noreferrer">
                                    <span className="display-inline-block"><img className="bg-main bg-round-100" src={PHONE} width={50} /></span>
                                    <span className="uk-width-medium uk-margin-left dis-contact">+54 223 6285111</span>
                                </a>
                            </div>
                        </section>
                    </div>
                    <div>
                        <h2 className="color-primary"> <b>Seguinos en redes</b> </h2>
                        <ul className="uk-list uk-margin-medium-top uk-text-center">
                            <li className="uk-display-inline uk-padding-small">
                                <button onClick={ () => { Functions.openWindow(DATA.FB) } } className="uk-button uk-button-link">
                                    <img className="bg-main bg-round-100" src={FACEBOOK} width={50} />
                                </button>
                            </li>
                            <li className="uk-display-inline uk-padding-small">
                                <button onClick={ () => { Functions.openWindow(DATA.IG) } } className="uk-button uk-button-link">
                                    <img className="bg-main bg-round-100" src={INSTAGRAM} width={50} />
                                </button>
                            </li>
                            {/* <li className="uk-display-inline uk-padding-small">
                                <button onClick={ () => { Functions.openWindow(DATA.YT) } } className="uk-button uk-button-link">
                                    <img className="bg-main bg-round-100" src={YOUTUBE} width={50} />
                                </button>
                            </li> */}
                        </ul>

                        <button className="uk-button uk-button-link uk-margin-top">
                            <img src={MAKER_BLUE} width={250} />
                        </button>

                    </div>

                </section>
            </div>
        </footer>
    );
}