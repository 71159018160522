import { Assets } from "../assets/Assets";

export const Clientes = () => {

    const {
        REMICOOP,
        SU_TAXI,
        R_OESTE,
        CATHEDRAL,
        HORIZONTE,
        MUNDO_REMIS
    } = Assets.IMG.CAROUSEL;

    const testimonios = [
        { 
            Nombre: 'Hernan, Remicoop', 
            Testiminio: 'El sistema funciona muy bien y cuando tenés un inconveniente, siempre estan presentes', 
            path: REMICOOP
        },
        { 
            Nombre: 'Alejandro, Su Taxi', 
            Testiminio: 'Gracias a la plataforma mi empresa esta automatizada y puedo darle seguimiento a mis viajes', 
            path: SU_TAXI
        },
        { 
            Nombre: 'Isaías, Remís Oeste', 
            Testiminio: 'GeoDAC me permitió incorporar autos a bajo costo, destaco además el buen servicio de post venta', 
            path: R_OESTE
        },
        { 
            Nombre: 'Federco, Cathedral', 
            Testiminio: 'Con la plataforma resolví conflictos entre operadores y conductores, también me permitió incorporar móviles con una mínima inversión', 
            path: CATHEDRAL
        },
        { 
            Nombre: 'Adrián, Horizonte', 
            Testiminio: 'Mejoró la organización de mi empresa permitiéndome crecer en forma sostenida', 
            path: HORIZONTE
        },
        { 
            Nombre: 'Bárbara, Mundo Remis', 
            Testiminio: 'GeoDAC me permitió aumentar la flota a bajo costo y me gusto mucho poder controlar los autos a través de los sensores', 
            path: MUNDO_REMIS
        },
    ]

    return (
        <div className="uk-container uk-padding">
            <div className="uk-text-center uk-margin-medium-top uk-margin-medium-bottom">
                <h2 className="uk-text-bolder font-open-sans-bold">Nuestros clientes dicen</h2>
            </div>

            <div className="uk-slider-container-offset uk-text-center"
                uk-slider="autoplay: true; autoplayInterval: 6000">

                <div className="uk-visible-toggle uk-light" tabIndex="-1">

                    <ul className="uk-grid uk-slider-items uk-child-width-1-3@m" >
                        {
                            testimonios.map((data, index) => (
                                <li key={index}>
                                    <article className="uk-comment uk-card uk-card-default uk-card-body uk-inline uk-background-default">
                                        <header className="uk-comment-header">
                                            <div className="uk-grid-medium uk-flex-middle uk-text-center">
                                                <div className="uk-width-auto">
                                                    <img src={Assets.ICON.QUOTATION} width={60} className="uk-margin-small-bottom"/>
                                                    {/* <span uk-icon="icon: quote-right; ratio: 4"
                                                        style={{color:'#00ADEF'}}></span> */}
                                                </div>
                                            </div>
                                        </header>
                                        <div className="uk-comment-body" style={{height:'180px'}}>
                                            <p className="uk-text-italic fs-px-24 font-card">
                                                {data.Testiminio}
                                            </p>
                                        </div>
                                        <div className="flex-right">
                                            <div>
                                                <div
                                                    className="uk-comment-meta uk-margin-top fs-px-18 font-roboto-condensed-light">
                                                    {data.Nombre}
                                                </div>
                                                <div className="uk-align-right uk-margin-top" style={{marginBottom: 0}}><img
                                                    src={data.path} width="75" /> </div>
                                            </div>
                                        </div>
                                    </article>
                                </li>
                            ))
                        }

                    </ul>

                </div>
            </div>
        </div>

    );
}