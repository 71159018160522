import './assets/styles.css';

import { Header } from './components/Header';
import { Main } from './components/Main';
import { Footer } from './components/Footer';
import { Nav } from './components/Nav';


function App() {
  return (
    <>
      <Nav />
      <Header /> 
      <Main />
      <Footer />

      <a href="#top" className="btn btn-float">
        <span> 
          <svg width="40" height="40" viewBox="0 0 20 20">
            <polygon points="10.5,4 15.37,9.4 14.63,10.08 10.5,5.49 6.37,10.08 5.63,9.4" fill="#FFF"></polygon>
            <line fill="none" stroke="#FFF" x1="10.5" y1="16" x2="10.5" y2="5"></line></svg>
        </span> 
      </a>
    </>
  );
}

export default App;
